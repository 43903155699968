
















































































































































































import {Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Payment } from '@/models';
import {GatewayAPI, MerchantAPI} from '@/api';
import {getBaseDomain} from "@/plugins/helpers";

@Component({
    methods: {
      GatewayAPI() {
        return GatewayAPI
      }, getBaseDomain}
})
export default class PaymentDetails extends Vue {
  public payment: Payment | null = null;

  private mounted() {
    MerchantAPI
        .paymentFind(this.$route.params.hash)
        .then((res) => this.payment = new Payment(res))
        .catch(err => alert(err));
  }
}
